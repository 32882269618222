import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import _ from 'lodash';

import { MenuPage } from 'src/modules/layout';
import { SEO, slugify } from 'src/modules/seo';

const getStateType = _.property('state.type');

const OthersPage = ({ transitionStatus, data, pageContext, entry, exit }) => {
  const { markdownRemark: post } = data;
  const { metaTitle, metaDescription, pages } = post.frontmatter;
  const { langKey } = post.fields;
  const currentPage = pages.find(({ title }) => slugify(title) === pageContext.slug);

  return (
    <MenuPage
      lang={langKey}
      routeRoot="others"
      items={pages}
      currentItem={currentPage}
      transition={{ status: transitionStatus, type: getStateType(entry) || getStateType(exit) }}
    >
      <SEO title={metaTitle} description={metaDescription} />
    </MenuPage>
  );
};

OthersPage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pages: PropTypes.arrayOf({
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
  transitionStatus: PropTypes.string.isRequired,
  entry: PropTypes.shape({
    state: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  exit: PropTypes.shape({
    state: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

OthersPage.defaultProps = {
  entry: null,
  exit: null,
};

export default OthersPage;

export const othersPageQuery = graphql`
  query OthersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      frontmatter {
        metaTitle
        metaDescription
        pages {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`;
